import React, { useState, useEffect } from 'react';
import "./App.css";
import { Grid } from '@mui/material';
import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';

const AnalyticsUrl = "https://analytics.evoluable.net/?module=API&method=Live.getLastVisitsDetails&period=day&date=today&format=JSON&token_auth=5d772df9bf7beb1990b57e2c56387039&idSite=";

function App() {
  const [data, setData] = useState(null);
  useEffect(() =>{
    const fetchData = async () => {
      try
      {
        var arrayJson = [];
        for(var i =1;i<8;i++)
        {
          const result = await fetch(AnalyticsUrl + i);
          arrayJson = arrayJson.concat(await result.json());
        }
        setData(arrayJson);
      }catch{
      }
      setTimeout(() => { fetchData(); }, 30000);
    };
    fetchData();
  }, []);

  const [time, setTime] = useState(null);
  function refreshTime(){
    setTime(new Date().getUTCHours().toString().padStart(2, '0') + ":" + new Date().getUTCMinutes().toString().padStart(2, '0') + "." + new Date().getUTCSeconds().toString().padStart(2, '0') + "UTC")
  }

  setInterval(() => { refreshTime(); }, 1000);

  function GetIcon(siteId){
    if(siteId === 1) return '/images/E.svg'; // evoluable
    if(siteId === 2) return '/images/U.svg'; // microkb
    if(siteId === 3) return '/images/F.svg'; // fchapleau
    if(siteId === 4) return '/images/Y.svg'; // byrnu
    if(siteId === 7) return '/images/P.svg'; // primefactor
  }
  
  return (
    <div className="App">      
      <Grid container className='App-Body'>
        <div className='App-Time'>{time}</div>
          {!data ? "Loading..." : 
            <VectorMap 
                regionStyle={{
                  initial: {
                    fill: 'grey',
                    stroke: "#676767",
                    strokeWidth: 2.5,
                    fillOpacity: 1
                  }
                }}
                normalizeFunction={'polynomial'}
                backgroundColor= {'black'}
                map={worldMill}                
                markers={
                  data.map(element => {  return { 
                      latLng: [element.latitude, element.longitude], 
                      name: element.location,
                      style: {                        
                        stroke: '#676767',
                        strokeWidth: 2.5,
                        fill: '#ff5566',
                        fillOpacity: 1,
                        image: GetIcon(element.idSite)
                      } 
                    }})
                }
            />
          }
      </Grid>
    </div>
  );
}

export default App;